import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { List } from '@alfalab/core-components/list';
import SectionHeader from '../section-header/SectionHeader';
import { Link } from '@alfalab/core-components/link';
import { Collapse } from '@alfalab/core-components/collapse';
import { PhoneCircleMIcon } from '@alfalab/icons-glyph/PhoneCircleMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';
import { HouseHeartMIcon } from '@alfalab/icons-glyph/HouseHeartMIcon';
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import { Divider } from '@alfalab/core-components/divider';

function ContactsPage() {
  return (
    <>
      <SectionHeader type="primary" title="Контакты" subtitle="" />

      <Space direction="vertical" size="s" fullWidth>
        <Typography.Text view="primary-large" tag="p">
          <Link href="tel:+79060616020" leftAddons={<PhoneCircleMIcon />}>
            +7 906 061-60-20
          </Link>
        </Typography.Text>
        <Typography.Text view="primary-large" tag="p">
          <Link href="mailto:info@alfabankstore.ru" leftAddons={<MailMIcon />}>
            info@alfabankstore.ru
          </Link>
        </Typography.Text>

        <Divider />

        <Space direction="horizontal" size={8}>
          <HouseHeartMIcon />
          <Typography.Text view="primary-large" tag="p">
            Москва, пр-т Андропова, 18 корп. 3
          </Typography.Text>
        </Space>

        <Typography.Text view="primary-large" tag="div">
          пн-чт: 10:00—19:00
        </Typography.Text>

        <Typography.Text view="primary-large" tag="div">
          пт: 10:00—17:30
        </Typography.Text>

        <YMaps>
          <Map
            style={{
              width: '100%',
              height: '320px',
            }}
            defaultState={{
              center: [55.694553, 37.662114],
              zoom: 15,
            }}
          >
            <Placemark geometry={[55.694553, 37.662114]} />
          </Map>
        </YMaps>

        <Divider />

        <Collapse
          collapsedLabel="Политика конфиденциальности и обработки персональных данных"
          expandedLabel="Скрыть политику конфиденциальности"
        >
          <Space size="s">
            <Typography.TitleResponsive tag="h2" view="medium">
              Политика в отношении обработки персональных данных
            </Typography.TitleResponsive>
            <Typography.TitleResponsive tag="h3" view="small">
              1. Общие положения
            </Typography.TitleResponsive>
            <Typography.Text>
              Настоящая политика обработки персональных данных составлена в
              соответствии с требованиями Федерального закона от 27.07.2006.
              № 152-ФЗ «О персональных данных» и определяет порядок обработки
              персональных данных и меры по обеспечению безопасности
              персональных данных ООО «Альфа Фьюче Пипл» (далее — Оператор).
            </Typography.Text>

            <List tag="ol">
              <Typography.Text>
                Оператор ставит своей важнейшей целью и условием осуществления
                своей деятельности соблюдение прав и свобод человека
                и гражданина при обработке его персональных данных, в том числе
                защиты прав на неприкосновенность частной жизни, личную
                и семейную тайну.
              </Typography.Text>

              <Typography.Text>
                Настоящая политика Оператора в отношении обработки персональных
                данных (далее — Политика) применяется ко всей информации,
                которую Оператор может получить о посетителях веб-сайта
                https://store.alfabank.ru.
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              2. Основные понятия, используемые в Политике
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>
                Автоматизированная обработка персональных данных — обработка
                персональных данных с помощью средств вычислительной техники;
              </Typography.Text>

              <Typography.Text>
                Блокирование персональных данных — временное прекращение
                обработки персональных данных (за исключением случаев, если
                обработка необходима для уточнения персональных данных);
              </Typography.Text>

              <Typography.Text>
                Веб-сайт — совокупность графических и информационных материалов,
                а также программ для ЭВМ и баз данных, обеспечивающих
                их доступность в сети интернет по сетевому адресу
                https://store.alfabank.ru;
              </Typography.Text>

              <Typography.Text>
                Информационная система персональных данных — совокупность
                содержащихся в базах данных персональных данных,
                и обеспечивающих их обработку информационных технологий
                и технических средств;
              </Typography.Text>

              <Typography.Text>
                Обезличивание персональных данных — действия, в результате
                которых невозможно определить без использования дополнительной
                информации принадлежность персональных данных конкретному
                Пользователю или иному субъекту персональных данных;
              </Typography.Text>

              <Typography.Text>
                Обработка персональных данных — любое действие (операция) или
                совокупность действий (операций), совершаемых с использованием
                средств автоматизации или без использования таких средств
                с персональными данными, включая сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение),
                извлечение, использование, передачу (распространение,
                предоставление, доступ), обезличивание, блокирование, удаление,
                уничтожение персональных данных;
              </Typography.Text>

              <Typography.Text>
                Оператор — государственный орган, муниципальный орган,
                юридическое или физическое лицо, самостоятельно или совместно
                с другими лицами организующие и (или) осуществляющие обработку
                персональных данных, а также определяющие цели обработки
                персональных данных, состав персональных данных, подлежащих
                обработке, действия (операции), совершаемые с персональными
                данными;
              </Typography.Text>

              <Typography.Text>
                Персональные данные — любая информация, относящаяся прямо или
                косвенно к определенному или определяемому Пользователю
                веб-сайта https://store.alfabank.ru;
              </Typography.Text>

              <Typography.Text>
                Пользователь — любой посетитель веб-сайта
                https://store.alfabank.ru;
              </Typography.Text>

              <Typography.Text>
                Предоставление персональных данных — действия, направленные
                на раскрытие персональных данных определенному лицу или
                определенному кругу лиц;
              </Typography.Text>

              <Typography.Text>
                Распространение персональных данных — любые действия,
                направленные на раскрытие персональных данных неопределенному
                кругу лиц (передача персональных данных) или на ознакомление
                с персональными данными неограниченного круга лиц, в том числе
                обнародование персональных данных в средствах массовой
                информации, размещение в информационно-телекоммуникационных
                сетях или предоставление доступа к персональным данным
                каким-либо иным способом;
              </Typography.Text>

              <Typography.Text>
                Трансграничная передача персональных данных — передача
                персональных данных на территорию иностранного государства
                органу власти иностранного государства, иностранному физическому
                или иностранному юридическому лицу;
              </Typography.Text>

              <Typography.Text>
                Уничтожение персональных данных — любые действия, в результате
                которых персональные данные уничтожаются безвозвратно
                с невозможностью дальнейшего восстановления содержания
                персональных данных в информационной системе персональных
                данных и (или) результате которых уничтожаются материальные
                носители персональных данных.
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              3. Оператор может обрабатывать следующие персональные данные
              Пользователя
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>Фамилия, имя, отчество; </Typography.Text>
              <Typography.Text>Электронный адрес;</Typography.Text>
              <Typography.Text>Номера телефонов;</Typography.Text>
              <Typography.Text>Адрес для доставки;</Typography.Text>
              <Typography.Text>
                Также на сайте происходит сбор и обработка обезличенных данных
                о посетителях (в т.ч. файлов „cookie“) с помощью сервисов
                интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).
              </Typography.Text>
              <Typography.Text>
                Вышеперечисленные данные далее по тексту Политики объединены
                общим понятием Персональные данные.
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              4. Цели обработки персональных данных
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>
                Цель обработки персональных данных Пользователя — заключение,
                исполнение и прекращение гражданско-правовых договоров;
                предоставление доступа Пользователю к сервисам, информации и/или
                материалам, содержащимся на веб-сайте https://store.alfabank.ru;
                уточнение деталей заказа.{' '}
              </Typography.Text>
              <Typography.Text>
                Также Оператор имеет право направлять Пользователю уведомления
                о новых продуктах и услугах, специальных предложениях
                и различных событиях. Пользователь всегда может отказаться
                от получения информационных сообщений, направив Оператору письмо
                на адрес электронной почты info@alfabankstore.ru с пометкой
                «Отказ от уведомлениях о новых продуктах и услугах и специальных
                предложениях».
              </Typography.Text>
              <Typography.Text>
                Обезличенные данные Пользователей, собираемые с помощью сервисов
                интернет-статистики, служат для сбора информации о действиях
                Пользователей на сайте, улучшения качества сайта и его
                содержания.
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              5. Правовые основания обработки персональных данных
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>
                Оператор обрабатывает персональные данные Пользователя только
                в случае их заполнения и/или отправки Пользователем
                самостоятельно через специальные формы, расположенные на сайте
                https://store.alfabank.ru. Заполняя соответствующие формы и/или
                отправляя свои персональные данные Оператору, Пользователь
                выражает свое согласие с данной Политикой.{' '}
              </Typography.Text>
              <Typography.Text>
                Оператор обрабатывает обезличенные данные о Пользователе
                в случае, если это разрешено в настройках браузера Пользователя
                (включено сохранение файлов «cookie» и использование технологии
                JavaScript).{' '}
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              6. Порядок сбора, хранения, передачи и других видов обработки
              персональных данных
            </Typography.TitleResponsive>

            <Typography.Text>
              Безопасность персональных данных, которые обрабатываются
              Оператором, обеспечивается путем реализации правовых,
              организационных и технических мер, необходимых для выполнения
              в полном объеме требований действующего законодательства в области
              защиты персональных данных.{' '}
            </Typography.Text>

            <List tag="ol">
              <Typography.Text>
                Оператор обеспечивает сохранность персональных данных
                и принимает все возможные меры, исключающие доступ
                к персональным данным неуполномоченных лиц.{' '}
              </Typography.Text>
              <Typography.Text>
                Персональные данные Пользователя никогда, ни при каких условиях
                не будут переданы третьим лицам, за исключением случаев,
                связанных с исполнением действующего законодательства.
              </Typography.Text>
              <Typography.Text>
                В случае выявления неточностей в персональных данных,
                Пользователь может актуализировать их самостоятельно, путем
                направления Оператору уведомление на адрес электронной почты
                Оператора info@alfabankstore.ru с пометкой «Актуализация
                персональных данных».
              </Typography.Text>
              <Typography.Text>
                Срок обработки персональных данных является неограниченным.
                Пользователь может в любой момент отозвать свое согласие
                на обработку персональных данных, направив Оператору уведомление
                посредством электронной почты на электронный адрес Оператора
                info@alfabankstore.ru с пометкой «Отзыв согласия на обработку
                персональных данных».
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              7. Трансграничная передача персональных данных
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>
                Оператор до начала осуществления трансграничной передачи
                персональных данных обязан убедиться в том, что иностранным
                государством, на территорию которого предполагается осуществлять
                передачу персональных данных, обеспечивается надежная защита
                прав субъектов персональных данных.{' '}
              </Typography.Text>
              <Typography.Text>
                Трансграничная передача персональных данных на территории
                иностранных государств, не отвечающих вышеуказанным требованиям,
                может осуществляться только в случае наличия согласия
                в письменной форме субъекта персональных данных
                на трансграничную передачу его персональных данных и/или
                исполнения договора, стороной которого является субъект
                персональных данных.{' '}
              </Typography.Text>
            </List>

            <Typography.TitleResponsive tag="h3" view="small">
              8. Заключительные положения
            </Typography.TitleResponsive>

            <List tag="ol">
              <Typography.Text>
                Пользователь может получить любые разъяснения по интересующим
                вопросам, касающимся обработки его персональных данных,
                обратившись к Оператору с помощью электронной почты
                info@alfabankstore.ru.{' '}
              </Typography.Text>
              <Typography.Text>
                В данном документе будут отражены любые изменения политики
                обработки персональных данных Оператором. Политика действует
                бессрочно до замены ее новой версией.
              </Typography.Text>
              <Typography.Text>
                Актуальная версия Политики в свободном доступе расположена
                в сети Интернет по адресу https://store.alfabank.ru/policy.
              </Typography.Text>
            </List>
          </Space>
        </Collapse>
      </Space>
    </>
  );
}

export default ContactsPage;
